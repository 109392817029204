
import { defineComponent, computed } from 'vue';
import { Difficulty } from '@/games/minesweeper/models';
import { gameQuery } from '@/state/game/game.query';
import { useObservable } from '@/core/rxjs-helpers';
import { coreUtil } from '@/core/core-util';
import { cellsQuery } from '@/games/minesweeper/state/cells/cells.query';

export default defineComponent({
    setup() {
        const difficulty = useObservable(cellsQuery.difficulty$, Difficulty.easy);
        const totalMines = useObservable(cellsQuery.totalMines$);
        const flagCount = useObservable(cellsQuery.flagCount$);
        const time = useObservable(
            gameQuery.select((x) => x.gameMetrics.time),
            0
        );

        const timeFormat = computed(() => {
            return coreUtil.timeFormat(time.value);
        });

        const minesLeft = computed(() => {
            return totalMines.value - flagCount.value;
        });

        return {
            difficulty,
            time,
            timeFormat,
            minesLeft,
        };
    },
});
