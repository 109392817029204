import { Controller } from '@/core/controller';
import { bus } from '@/games/minesweeper/bus';
import { Cell } from '@/games/minesweeper/models';
import { cellsService } from '@/games/minesweeper/state/cells/cells.service';
import { cellsQuery } from '@/games/minesweeper/state/cells/cells.query';
import { transaction } from '@datorama/akita';
import { gameService } from '@/state/game/game.service';
import { GameStatus } from '@/core/models';
import { coreBus } from '@/core/core-bus';

export class ActionController extends Controller {
    constructor() {
        super();
        this.subscription.add(
            bus.cellSafeCmd$.subscribe((cmd) => {
                this.markAsSafe(cmd.cell);
                coreBus.gameMoveCompletedEvent$.next();
            })
        );
        this.subscription.add(
            bus.cellFlagCmd$.subscribe((cmd) => {
                this.markAsFlag(cmd.cell);
                coreBus.gameMoveCompletedEvent$.next();
            })
        );
        this.subscription.add(
            bus.cellMaybeCmd$.subscribe((cmd) => {
                this.markAsMaybe(cmd.cell);
                coreBus.gameMoveCompletedEvent$.next();
            })
        );
        this.subscription.add(
            bus.cellClearMarksCmd$.subscribe((cmd) => {
                this.clearMarks(cmd.cell);
                coreBus.gameMoveCompletedEvent$.next();
            })
        );
    }

    @transaction()
    private markAsSafe(cellIn: Cell) {
        let cell = cellIn;
        // if this is first move and user clicked on mine we need to move the mine
        const countPressed = cellsQuery.getAll().filter((c) => c.pressed);
        if (countPressed.length == 0 && cellIn.hasMine) {
            bus.firstMoveMineEvent$.next({
                cell: cellIn,
            });
            // eslint-disable-next-line
            cell = cellsQuery.getEntity(cellIn.id)!;
        }
        cellsService.setPressed(cell.id);
        if (cell.hasMine) {
            cellsService.setError(cell.id);
            cellsService.pressedAllMines();
            gameService.setGameStatus(GameStatus.gameFailed);
            return;
        }
        if (cell.hintNumber == 0 && !cell.pressed) {
            this.markSafeRecursive(cell);
        }
    }

    private markSafeRecursive(cell: Cell) {
        if (cell.hintNumber == 0 && !cell.pressed) {
            const empties = cellsQuery.getSurroundingCells(cell).filter((c) => c && !c.pressed);
            empties.forEach((c) => {
                if (c) {
                    cellsService.setPressed(c.id);
                    if (c.hintNumber == 0 && !c.pressed) {
                        this.markSafeRecursive(c);
                    }
                }
            });
        }
    }

    private markAsFlag(cell: Cell) {
        cellsService.setFlag(cell.id);
    }

    private markAsMaybe(cell: Cell) {
        cellsService.setMaybe(cell.id);
    }

    private clearMarks(cell: Cell) {
        cellsService.clearMarks(cell.id);
    }
}
