import { Controller } from '@/core/controller';
import { GameController } from '@/games/minesweeper/controller/game.controller';
import { ActionController } from '@/games/minesweeper/controller/action.controller';

export class Controllers {
    private controllers: Controller[] = [];

    init() {
        this.controllers.push(new GameController());
        this.controllers.push(new ActionController());
    }

    destroy() {
        this.controllers.forEach((c) => c.destroy());
    }
}

export const controllers = new Controllers();
