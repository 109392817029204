import { QueryEntity } from '@datorama/akita';
import { CellsState, cellsStore } from '@/games/minesweeper/state/cells/cells.store';
import { Cell } from '@/games/minesweeper/models';

class CellsQuery extends QueryEntity<CellsState> {
    cells$ = this.selectAll();

    totalMines$ = this.selectCount((c) => c.hasMine);

    flagCount$ = this.selectCount((c) => c.isFlag);

    difficulty$ = this.select((state) => state.difficulty);

    boardSize$ = this.select((state) => state.boardSize);

    getSurroundingCells(c: Cell) {
        const ids = [
            `${c.row - 1}-${c.col - 1}`,
            `${c.row - 1}-${c.col}`,
            `${c.row - 1}-${c.col + 1}`,
            `${c.row}-${c.col + 1}`,
            `${c.row + 1}-${c.col + 1}`,
            `${c.row + 1}-${c.col}`,
            `${c.row + 1}-${c.col - 1}`,
            `${c.row}-${c.col - 1}`,
        ];
        return ids.map((id) => this.getEntity(id)).filter((c) => !!c);
    }
}

export const cellsQuery = new CellsQuery(cellsStore);
