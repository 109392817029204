<template>
    <div class="max-w-screen-sm mx-auto w-full flex-shrink" style="max-width: 450px">
        <div class="flex w-full">
            <div>
                <div class="dropdown inline-block relative">
                    <button class="pb-2 px-2 rounded inline-flex items-center focus:outline-none">
                        <span class="mr-1 text-lg">{{ difficulty }}</span>
                        <svg
                            class="fill-current h-4 w-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                        >
                            <path
                                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                            />
                        </svg>
                    </button>
                    <ul class="dropdown-menu absolute hidden text-gray-200 pt-1 ml-3">
                        <li class="">
                            <router-link
                                to="/minesweeper-easy/play"
                                class="rounded-t hover:bg-gray-500 bg-gray-700 py-2 px-8 block whitespace-no-wrap"
                                href="#"
                                >Easy</router-link
                            >
                        </li>
                        <li class="">
                            <router-link
                                to="/minesweeper-medium/play"
                                class="bg-gray-700 hover:bg-gray-500 py-2 px-8 block whitespace-no-wrap"
                                href="#"
                                >Medium</router-link
                            >
                        </li>
                        <li class="">
                            <router-link
                                to="/minesweeper-expert/play"
                                class="rounded-b bg-gray-700 hover:bg-gray-500 py-2 px-8 block whitespace-no-wrap"
                                href="#"
                                >Expert</router-link
                            >
                        </li>
                    </ul>
                </div>
            </div>
            <div class="flex-grow text-center mr-4">
                <div class="pt-1 mr-1" style="display: inline-block; width: 1rem; height: 1rem">
                    <svg aria-hidden="true" focusable="false" role="img" viewBox="0 0 576 512">
                        <path
                            fill="currentColor"
                            d="M571 228.5c-78.1 88.2-179.8 108.8-184.1 109.6-134.8 26.1-153.3 7.5-237.1 37.5-10.6 3.8-21.8-3.6-21.8-14.8V112.5c0-9 7.3-16.4 16.3-16 43.2 2 95.3 13.2 155.2 42.4 140.6 68.5 223.7 62.9 252.9 57.2 18-3.8 31.3 18.1 18.6 32.4zM56 0C25.1 0 0 25.1 0 56c0 22.3 13.2 41.4 32 50.4V504c0 4.4 3.6 8 8 8h32c4.4 0 8-3.6 8-8V106.4c18.8-9 32-28.1 32-50.4 0-30.9-25.1-56-56-56z"
                        ></path>
                    </svg>
                </div>
                {{ minesLeft }}
            </div>
            <div class="align-middle inline-block pb-2 text-lg mr-3">
                {{ timeFormat }}
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { Difficulty } from '@/games/minesweeper/models';
import { gameQuery } from '@/state/game/game.query';
import { useObservable } from '@/core/rxjs-helpers';
import { coreUtil } from '@/core/core-util';
import { cellsQuery } from '@/games/minesweeper/state/cells/cells.query';

export default defineComponent({
    setup() {
        const difficulty = useObservable(cellsQuery.difficulty$, Difficulty.easy);
        const totalMines = useObservable(cellsQuery.totalMines$);
        const flagCount = useObservable(cellsQuery.flagCount$);
        const time = useObservable(
            gameQuery.select((x) => x.gameMetrics.time),
            0
        );

        const timeFormat = computed(() => {
            return coreUtil.timeFormat(time.value);
        });

        const minesLeft = computed(() => {
            return totalMines.value - flagCount.value;
        });

        return {
            difficulty,
            time,
            timeFormat,
            minesLeft,
        };
    },
});
</script>

<style scoped>
.dropdown:hover .dropdown-menu {
    display: block;
    z-index: 99;
}
</style>
