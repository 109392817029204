import { Size, Position } from '@/core/models';
import { BoardSize, Cell } from '@/games/minesweeper/models';

export class Display {
    readonly gameSize: Size;
    readonly boardSize: BoardSize;
    readonly isPortrait: boolean;
    readonly cellSize: Size;
    readonly stageMaxWidth: number;

    constructor(gameSize: Size, boardSize: BoardSize) {
        const isPortrait = gameSize.height > gameSize.width;
        const stageMaxWidth = boardSize.columns * 28;

        this.cellSize = {
            width: stageMaxWidth / boardSize.columns,
            height: stageMaxWidth / boardSize.columns,
        } as Size;

        this.gameSize = gameSize;
        this.boardSize = boardSize;
        this.isPortrait = isPortrait;
        this.stageMaxWidth = stageMaxWidth;
    }

    getCellPosition(cell: Cell): Position {
        const x = (cell.col - 1) * this.cellSize.width;
        const y = (cell.row - 1) * this.cellSize.height;
        return {
            x,
            y,
        };
    }
}
