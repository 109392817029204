import { Cell, Difficulty } from '@/games/minesweeper/models';
import { cellsStore } from '@/games/minesweeper/state/cells/cells.store';
import { cellsQuery } from '@/games/minesweeper/state/cells/cells.query';

class CellsService {
    setCells(cells: Cell[]) {
        cellsStore.set(cells);
    }

    setPressed(id: string) {
        cellsStore.update(id, {
            pressed: true,
        });
    }

    setFlag(id: string) {
        cellsStore.update(id, {
            isFlag: true,
            isMaybe: false,
        });
    }

    setMaybe(id: string) {
        cellsStore.update(id, {
            isMaybe: true,
            isFlag: false,
        });
    }

    clearMarks(id: string) {
        cellsStore.update(id, {
            isMaybe: false,
            isFlag: false,
        });
    }

    setError(id: string) {
        cellsStore.update(id, {
            pressed: true,
            isError: true,
        });
    }

    switchMineOwner(oldOwner: string, newOwner: string) {
        cellsStore.update(oldOwner, {
            hasMine: false,
        });
        cellsStore.update(newOwner, {
            hasMine: true,
        });
    }

    pressedAllMines() {
        const ids = cellsQuery
            .getAll()
            .filter((c) => c.hasMine)
            .map((c) => c.id);
        cellsStore.update(ids, {
            pressed: true,
        });
    }

    setDifficulty(difficulty: Difficulty) {
        cellsStore.update({
            difficulty,
        });
    }

    setBoardSize(rows: number, columns: number) {
        cellsStore.update({
            boardSize: {
                rows,
                columns,
            },
        });
    }
}

export const cellsService = new CellsService();
