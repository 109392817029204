<template>
    <div class="stage-wrapper">
        <div ref="gameStage" class="game-stage mx-auto" :style="wrapperStyle">
            <board ref="board" />
        </div>
        <div @click="slideLeft" class="scroll-left-arrow" v-show="canScrollLeft">
            <svg aria-hidden="true" focusable="false" role="img" viewBox="0 0 256 512">
                <path
                    fill="currentColor"
                    d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z"
                ></path>
            </svg>
        </div>
        <div @click="slideRight" class="scroll-right-arrow" v-show="canScrollRight">
            <svg aria-hidden="true" focusable="false" role="img" viewBox="0 0 256 512">
                <path
                    fill="currentColor"
                    d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z"
                ></path>
            </svg>
        </div>
        <div @click="slideTop" class="scroll-top-arrow" v-show="canScrollTop">
            <svg aria-hidden="true" focusable="false" role="img" viewBox="0 0 320 512">
                <path
                    fill="currentColor"
                    d="M177 159.7l136 136c9.4 9.4 9.4 24.6 0 33.9l-22.6 22.6c-9.4 9.4-24.6 9.4-33.9 0L160 255.9l-96.4 96.4c-9.4 9.4-24.6 9.4-33.9 0L7 329.7c-9.4-9.4-9.4-24.6 0-33.9l136-136c9.4-9.5 24.6-9.5 34-.1z"
                ></path>
            </svg>
        </div>
        <div @click="slideBottom" class="scroll-bottom-arrow" v-show="canScrollBottom">
            <svg aria-hidden="true" focusable="false" role="img" viewBox="0 0 320 512">
                <path
                    fill="currentColor"
                    d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"
                ></path>
            </svg>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, computed } from 'vue';
import Board from '@/games/minesweeper/views/Board.vue';
import { useObservable } from '@/core/rxjs-helpers';
import { cellsQuery } from '@/games/minesweeper/state/cells/cells.query';

export default defineComponent({
    components: {
        Board,
    },

    setup() {
        const gameStage = ref<HTMLElement | null>(null);
        const canScrollRight = ref(false);
        const canScrollLeft = ref(false);
        const canScrollTop = ref(false);
        const canScrollBottom = ref(false);
        const boardSize = useObservable(cellsQuery.boardSize$);

        const updateScrollIndicators = () => {
            const stage = gameStage.value as HTMLElement;
            canScrollRight.value = stage.scrollLeft + stage.clientWidth < stage.scrollWidth;
            canScrollLeft.value = stage.scrollLeft > 0;
            canScrollTop.value = stage.scrollTop > 0;
            canScrollBottom.value = stage.scrollTop + stage.clientHeight < stage.scrollHeight;
        };

        const wrapperStyle = computed(() => {
            return {
                width: `${boardSize.value.columns * 28 + 10}px`,
                maxWidth: `${boardSize.value.columns * 28 + 10}px`,
            };
        });

        const slideRight = () => {
            const stage = gameStage.value as HTMLElement;
            stage.scrollTo(stage.scrollLeft + 30, stage.scrollTop);
        };

        const slideLeft = () => {
            const stage = gameStage.value as HTMLElement;
            stage.scrollTo(stage.scrollLeft - 30, stage.scrollTop);
        };

        const slideTop = () => {
            const stage = gameStage.value as HTMLElement;
            stage.scrollTo(stage.scrollLeft, stage.scrollTop - 30);
        };

        const slideBottom = () => {
            const stage = gameStage.value as HTMLElement;
            stage.scrollTo(stage.scrollLeft, stage.scrollTop + 30);
        };

        onMounted(() => {
            const stage = gameStage.value as HTMLElement;
            stage.onscroll = () => {
                updateScrollIndicators();
            };
            setTimeout(() => {
                updateScrollIndicators();
            });
        });

        return {
            gameStage,
            canScrollRight,
            canScrollLeft,
            wrapperStyle,
            canScrollTop,
            canScrollBottom,
            slideRight,
            slideLeft,
            slideTop,
            slideBottom,
        };
    },
});
</script>

<style scoped>
.stage-wrapper {
    width: 100%;
    position: relative;
    flex: 1 1 auto;
    display: flex;
    box-sizing: border-box;
    margin-bottom: 80px;
}
.game-stage {
    position: relative;
    overflow: auto;
    flex: 1 1 auto;
    display: flex;
}
.scroll-left-arrow {
    top: 45%;
    left: 5px;
    position: absolute;
    color: #882405;
    width: 30px;
    height: 30px;
}
.scroll-right-arrow {
    top: 45%;
    right: 5px;
    position: absolute;
    color: #882405;
    width: 30px;
    height: 30px;
}
.scroll-top-arrow {
    top: -10px;
    right: 45%;
    position: absolute;
    color: #882405;
    width: 38px;
    height: 30px;
}
.scroll-bottom-arrow {
    bottom: 18px;
    right: 45%;
    position: absolute;
    color: #882405;
    width: 38px;
    height: 30px;
}
</style>
