import { bus } from '@/games/minesweeper/bus';
import { gameQuery } from '@/state/game/game.query';
import { gameService } from '@/state/game/game.service';
import { BoardSize, Cell, Difficulty } from '@/games/minesweeper/models';
import { PredictableRandom } from '@/core/predictable-random';
import { cellsService } from '@/games/minesweeper/state/cells/cells.service';
import { cellsQuery } from '@/games/minesweeper/state/cells/cells.query';
import { appQuery } from '@/state/app.query';
import { BaseGameController } from '@/core/base-game.controller';
import { Game, GameStatus } from '@/core/models';

export class GameController extends BaseGameController {
    protected initGame(): void {
        const game = appQuery.getActiveGame();
        if (game == Game.minesweeperEasy) {
            this.createBoard(Difficulty.easy);
        } else if (game == Game.minesweeperMedium) {
            this.createBoard(Difficulty.medium);
        } else if (game == Game.minesweeperExpert) {
            this.createBoard(Difficulty.expert);
        }
    }

    protected isGameCompleted(): boolean {
        const mineWithoutFlag = cellsQuery.getAll().filter((c) => c.hasMine && !c.isFlag);
        if (mineWithoutFlag.length > 0) {
            return false;
        }

        const flagWithoutMine = cellsQuery.getAll().filter((c) => !c.hasMine && c.isFlag);
        if (flagWithoutMine.length > 0) {
            return false;
        }

        const noFlagNoPressed = cellsQuery.getAll().filter((c) => !c.isFlag && !c.pressed);
        return noFlagNoPressed.length <= 0;
    }

    constructor() {
        super();
        this.subscription.add(
            bus.firstMoveMineEvent$.subscribe((ev) => {
                this.handleFirstMoveMine(ev.cell);
            })
        );
        this.subscription.add(
            gameQuery.gameStatus$.subscribe((status) => {
                if (status == GameStatus.readyToDeal) {
                    gameService.setGameStatus(GameStatus.dealCompleted);
                }
            })
        );
    }

    private async createBoard(difficulty: Difficulty) {
        // set board size
        let mineCount;
        if (difficulty == Difficulty.easy) {
            cellsService.setBoardSize(8, 8);
            mineCount = 10;
        } else if (difficulty == Difficulty.medium) {
            cellsService.setBoardSize(16, 16);
            mineCount = 40;
        } else {
            cellsService.setBoardSize(16, 31);
            mineCount = 99;
        }

        cellsService.setDifficulty(difficulty);

        const gameId = gameQuery.getValue().gameId;
        if (!gameId) {
            throw new Error('missing gameId');
        }

        // set cells
        const boardSize = cellsQuery.getValue().boardSize;
        const mines = this.genMines(mineCount, boardSize, gameId);
        const cells: Cell[] = [];
        for (let r = 1; r <= boardSize.rows; r++) {
            for (let c = 1; c <= boardSize.columns; c++) {
                cells.push({
                    id: `${r}-${c}`,
                    row: r,
                    col: c,
                    hasMine: !!mines[`${r}-${c}`],
                    hintNumber: 0,
                    pressed: false,
                } as Cell);
            }
        }

        cellsService.setCells(this.genHints(cells));
    }

    private genMines(amount: number, boardSize: BoardSize, gameId: number) {
        const isPortrait = boardSize.rows > boardSize.columns;
        const long = Math.max(boardSize.rows, boardSize.columns);
        const short = Math.min(boardSize.rows, boardSize.columns);
        const rand = new PredictableRandom(gameId);

        const mines: { [id: string]: number } = {};
        for (let i = 0; i < amount; i++) {
            let found = false;
            while (!found) {
                const l = Math.ceil(rand.next() * long);
                const s = Math.ceil(rand.next() * short);
                const row = isPortrait ? l : s;
                const col = isPortrait ? boardSize.columns + 1 - s : l;
                const id = `${row}-${col}`;
                if (!mines[id]) {
                    mines[id] = 1;
                    found = true;
                }
            }
        }

        return mines;
    }

    private genHints(cells: Cell[]) {
        const hansMine = (row: number, col: number) => {
            const cell = cells.filter((c) => c.row == row && c.col == col);
            return cell.length != 0 && cell[0].hasMine;
        };

        return cells.map((cell) => {
            let count = 0;
            // top left
            if (hansMine(cell.row - 1, cell.col - 1)) {
                count += 1;
            }
            // top middle
            if (hansMine(cell.row - 1, cell.col)) {
                count += 1;
            }
            // top right
            if (hansMine(cell.row - 1, cell.col + 1)) {
                count += 1;
            }
            // middle right
            if (hansMine(cell.row, cell.col + 1)) {
                count += 1;
            }
            // bottom right
            if (hansMine(cell.row + 1, cell.col + 1)) {
                count += 1;
            }
            // bottom middle
            if (hansMine(cell.row + 1, cell.col)) {
                count += 1;
            }
            // bottom left
            if (hansMine(cell.row + 1, cell.col - 1)) {
                count += 1;
            }
            // left middle
            if (hansMine(cell.row, cell.col - 1)) {
                count += 1;
            }

            return {
                ...cell,
                hintNumber: count,
            };
        });
    }

    private handleFirstMoveMine(cell: Cell) {
        // get surrounding cells and pick one to move the mine to
        const cells = cellsQuery.getSurroundingCells(cell).filter((c) => c && !c.hasMine);
        if (cells && cells.length > 0 && cells[0]) {
            cellsService.switchMineOwner(cell.id, cells[0].id);
        } else {
            // select random new owner
            const cells = cellsQuery.getAll().filter((c) => !c.hasMine);
            const selected = cells[Math.floor(Math.random() * cells.length)];
            if (selected) {
                cellsService.switchMineOwner(cell.id, selected.id);
            }
        }

        // we need to update hints
        cellsService.setCells(this.genHints(cellsQuery.getAll()));
    }

    protected handleResize() {
        console.log('--handle resize minesweeper');
    }
}
