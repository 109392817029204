import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { BoardSize, Cell, Difficulty } from '@/games/minesweeper/models';

export interface CellsState extends EntityState<Cell> {
    difficulty: Difficulty;

    boardSize: BoardSize;
}

const initialState = {
    difficulty: Difficulty.easy,
    boardSize: { rows: 0, columns: 0 },
};

@StoreConfig({ name: 'mine-cells' })
export class CellsStore extends EntityStore<CellsState> {
    constructor() {
        super(initialState);
    }
}

export const cellsStore = new CellsStore();
