<template>
    <div class="game-wrapper flex-grow">
        <status-panel />
        <stage v-show="!showGameCompleteView" />
        <action-panel :hide-undo="true" />
        <game-complete-panel v-if="showGameCompleteView" />
        <div
            v-show="!showGameCompleteView"
            style="position: fixed; bottom: 60px; font-size: 90%; color: #eee"
        >
            Game: #{{ gameId }}
        </div>
        <game-menu
            v-model="showGameMenu"
            :game="game"
            :game-id="gameId"
            :game-in-progress="isGameInProgress"
            @start-new-game="startNewGame"
            @stats-click="statsClicked"
            :hide-daily-challenge="true"
        />
    </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeUnmount, onMounted, ref, computed } from 'vue';
import Stage from '@/games/minesweeper/views/Stage.vue';
import { controllers } from '@/games/minesweeper/controller/controllers';
import { isNaN } from 'lodash';
import { useRoute } from 'vue-router';
import StatusPanel from '@/games/minesweeper/views/StatusPanel.vue';
import ActionPanel from '@/components/ActionPanel.vue';
import { subscribeTo } from '@/core/rxjs-helpers';
import { gameQuery } from '@/state/game/game.query';
import { appQuery } from '@/state/app.query';
import { GameStatus } from '@/core/models';
import GameMenu from '@/components/GameMenu.vue';
import { coreUtil } from '@/core/core-util';
import confetti from 'canvas-confetti';
import GameCompletePanel from '@/components/GameCompletePanel.vue';
import { coreBus } from '@/core/core-bus';

export default defineComponent({
    components: {
        Stage,
        StatusPanel,
        ActionPanel,
        GameMenu,
        GameCompletePanel,
    },

    setup() {
        const board = ref<HTMLElement | null>(null);
        const route = useRoute();
        const showGameMenu = ref(false);
        const gameId = ref(0);
        const gameStatus = ref(GameStatus.none);
        const showGameCompleteView = ref(false);

        subscribeTo(coreBus.openGameMenu$, () => {
            showGameMenu.value = true;
        });

        subscribeTo(
            gameQuery.select((s) => s.gameId),
            (id) => {
                gameId.value = id || 0;
            }
        );

        const handleGameCompleted = async () => {
            // show the complete panel and
            await coreUtil.delay(1000);
            showGameCompleteView.value = true;

            // show confetti
            await coreUtil.delay(500);
            confetti({
                particleCount: 120,
                spread: 60,
            });
        };

        subscribeTo(gameQuery.gameStatus$, (status) => {
            gameStatus.value = status;
            if (status == GameStatus.gameCompleted) {
                handleGameCompleted();
            } else {
                showGameCompleteView.value = false;
            }
        });

        const game = computed(() => {
            return appQuery.getActiveGame();
        });

        const isGameInProgress = computed(() => {
            return gameStatus.value == GameStatus.running || gameStatus.value == GameStatus.paused;
        });

        const startNewGame = (gameId: number | null) => {
            coreBus.startGameCmd$.next({
                gameId: gameId,
                game: game.value,
            });
        };

        const statsClicked = () => {
            coreBus.showGameStatisticDialogCmd$.next({
                game: game.value,
                hideMoveData: true,
                hideScoreData: true,
            });
        };

        onMounted(() => {
            controllers.init();
            const sid = route.params.gameId as string | undefined;
            const gameId = sid && !isNaN(sid) ? parseInt(sid, 10) : null;
            coreBus.startGameCmd$.next({
                gameId: gameId,
                game: game.value,
            });
        });

        onBeforeUnmount(() => {
            controllers.destroy();
        });

        return {
            board,
            showGameMenu,
            gameId,
            game,
            isGameInProgress,
            startNewGame,
            statsClicked,
            showGameCompleteView,
        };
    },
});
</script>

<style scoped>
.game-wrapper {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 5px;
    box-sizing: border-box;
    position: relative;
    max-width: 900px;
}
</style>
