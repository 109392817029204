export type Cell = {
    id: string;
    row: number;
    col: number;
    index: number;
    hasMine: boolean;
    hintNumber: number;
    pressed: boolean;
    isFlag: boolean;
    isMaybe: boolean;
    isError: boolean;
};

export type BoardSize = {
    rows: number;
    columns: number;
};

export enum Difficulty {
    easy = 'easy',
    medium = 'medium',
    expert = 'expert',
}
