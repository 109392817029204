
import { defineComponent, onMounted, ref, computed } from 'vue';
import Board from '@/games/minesweeper/views/Board.vue';
import { useObservable } from '@/core/rxjs-helpers';
import { cellsQuery } from '@/games/minesweeper/state/cells/cells.query';

export default defineComponent({
    components: {
        Board,
    },

    setup() {
        const gameStage = ref<HTMLElement | null>(null);
        const canScrollRight = ref(false);
        const canScrollLeft = ref(false);
        const canScrollTop = ref(false);
        const canScrollBottom = ref(false);
        const boardSize = useObservable(cellsQuery.boardSize$);

        const updateScrollIndicators = () => {
            const stage = gameStage.value as HTMLElement;
            canScrollRight.value = stage.scrollLeft + stage.clientWidth < stage.scrollWidth;
            canScrollLeft.value = stage.scrollLeft > 0;
            canScrollTop.value = stage.scrollTop > 0;
            canScrollBottom.value = stage.scrollTop + stage.clientHeight < stage.scrollHeight;
        };

        const wrapperStyle = computed(() => {
            return {
                width: `${boardSize.value.columns * 28 + 10}px`,
                maxWidth: `${boardSize.value.columns * 28 + 10}px`,
            };
        });

        const slideRight = () => {
            const stage = gameStage.value as HTMLElement;
            stage.scrollTo(stage.scrollLeft + 30, stage.scrollTop);
        };

        const slideLeft = () => {
            const stage = gameStage.value as HTMLElement;
            stage.scrollTo(stage.scrollLeft - 30, stage.scrollTop);
        };

        const slideTop = () => {
            const stage = gameStage.value as HTMLElement;
            stage.scrollTo(stage.scrollLeft, stage.scrollTop - 30);
        };

        const slideBottom = () => {
            const stage = gameStage.value as HTMLElement;
            stage.scrollTo(stage.scrollLeft, stage.scrollTop + 30);
        };

        onMounted(() => {
            const stage = gameStage.value as HTMLElement;
            stage.onscroll = () => {
                updateScrollIndicators();
            };
            setTimeout(() => {
                updateScrollIndicators();
            });
        });

        return {
            gameStage,
            canScrollRight,
            canScrollLeft,
            wrapperStyle,
            canScrollTop,
            canScrollBottom,
            slideRight,
            slideLeft,
            slideTop,
            slideBottom,
        };
    },
});
