<template>
    <div class="board-wrapper">
        <div
            v-for="c in cells"
            :key="`${c.row}${c.col}`"
            :style="getCellStyle(c)"
            class="cell noselect"
            :class="getCellClass(c)"
            @mousedown="onmousedown(c, $event)"
            @mouseup="onmouseup(c, $event)"
            @touchstart="onmousedown(c, $event)"
            @touchend="onmouseup(c, $event)"
            @touchmove="onmousemove(c, $event)"
            @contextmenu.prevent
        >
            <span v-if="c.hintNumber > 0 && c.pressed && !c.hasMine">{{ c.hintNumber }}</span>
            <div v-if="c.isFlag && !c.pressed" class="p-1 text-red-600">
                <svg aria-hidden="true" focusable="false" role="img" viewBox="0 0 576 512">
                    <path
                        fill="currentColor"
                        d="M571 228.5c-78.1 88.2-179.8 108.8-184.1 109.6-134.8 26.1-153.3 7.5-237.1 37.5-10.6 3.8-21.8-3.6-21.8-14.8V112.5c0-9 7.3-16.4 16.3-16 43.2 2 95.3 13.2 155.2 42.4 140.6 68.5 223.7 62.9 252.9 57.2 18-3.8 31.3 18.1 18.6 32.4zM56 0C25.1 0 0 25.1 0 56c0 22.3 13.2 41.4 32 50.4V504c0 4.4 3.6 8 8 8h32c4.4 0 8-3.6 8-8V106.4c18.8-9 32-28.1 32-50.4 0-30.9-25.1-56-56-56z"
                    ></path>
                </svg>
            </div>
            <div v-if="c.isMaybe">?</div>
            <div v-if="c.hasMine && c.pressed" style="padding: 3px">
                <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="23" cy="23" r="17" />
                    <path
                        stroke-linecap="round"
                        stroke-width="6"
                        stroke="#000"
                        d="M8 8l32 32M8 40L40 8M4 24h40M24 4v40"
                    />
                    <path
                        d="M10 24a14 14 0 0114-14"
                        stroke="#848484"
                        fill="none"
                        stroke-linecap="round"
                        stroke-width="3"
                    />
                </svg>
            </div>
        </div>
        <div
            v-show="!!cellPress"
            :style="getPressInfoStyle"
            class="mine-press-info rounded-lg shadow-lg bg-yellow-200 border border-yellow-400 p-2"
        >
            <svg
                aria-hidden="true"
                focusable="false"
                role="img"
                viewBox="0 0 576 512"
                style="color: red"
                v-show="pressCounter % 3 === 1"
            >
                <path
                    fill="currentColor"
                    d="M571 228.5c-78.1 88.2-179.8 108.8-184.1 109.6-134.8 26.1-153.3 7.5-237.1 37.5-10.6 3.8-21.8-3.6-21.8-14.8V112.5c0-9 7.3-16.4 16.3-16 43.2 2 95.3 13.2 155.2 42.4 140.6 68.5 223.7 62.9 252.9 57.2 18-3.8 31.3 18.1 18.6 32.4zM56 0C25.1 0 0 25.1 0 56c0 22.3 13.2 41.4 32 50.4V504c0 4.4 3.6 8 8 8h32c4.4 0 8-3.6 8-8V106.4c18.8-9 32-28.1 32-50.4 0-30.9-25.1-56-56-56z"
                ></path>
            </svg>
            <div v-show="pressCounter % 3 === 2" style="padding-top: 0px">?</div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue';
import { gameQuery } from '@/state/game/game.query';
import { Cell } from '@/games/minesweeper/models';
import { Display } from '@/games/minesweeper/display';
import { Size } from '@/core/models';
import { cellsQuery } from '@/games/minesweeper/state/cells/cells.query';
import { bus } from '@/games/minesweeper/bus';

export default defineComponent({
    setup() {
        const gameSize = ref<Size>({ width: 0, height: 0 });
        const cells = ref<Cell[]>([]);
        const cellPress = ref<Cell | null>(null);
        const pressCounter = ref(0);
        let display = new Display({ width: 0, height: 0 }, { rows: 0, columns: 0 });
        const pointerX = ref(0);
        const pointerY = ref(0);
        let pressInterval: number | undefined = undefined;
        let isMoved = false;
        let isTouchEvent = false;

        cellsQuery.cells$.subscribe((list) => {
            display = new Display(gameQuery.getValue().gameSize, cellsQuery.getValue().boardSize);
            cells.value = list;
        });

        gameQuery.gameSize$.subscribe((size) => {
            gameSize.value = size;
            display = new Display(size, cellsQuery.getValue().boardSize);
            cells.value = [...cells.value];
        });

        const getCellStyle = (cell: Cell) => {
            const pos = display.getCellPosition(cell);
            let color = '#000000';
            if (cell.hasMine || cell.isMaybe) {
                color = '#000000';
            } else if (cell.hintNumber == 1) {
                color = '#3182CE';
            } else if (cell.hintNumber == 2) {
                color = '#2F855A';
            } else if (cell.hintNumber == 3) {
                color = '#C53030';
            } else if (cell.hintNumber == 4) {
                color = '#2C5282';
            } else if (cell.hintNumber >= 5) {
                color = '#97266D';
            }
            return {
                left: `${pos.x}px`,
                top: `${pos.y}px`,
                width: `${display.cellSize.width}px`,
                height: `${display.cellSize.height}px`,
                lineHeight: `${display.cellSize.height - 3}px`,
                color,
            };
        };

        const getCellClass = (cell: Cell) => {
            const arr = [];
            if (cell.pressed) {
                arr.push('pressed');
                if (cell.isError) {
                    arr.push('mine-error');
                }
            }
            return arr.join(' ');
        };

        const getPressInfoStyle = computed(() => {
            if (!cellPress.value) {
                return {};
            }
            let x = pointerX.value - 25;
            if (isTouchEvent) {
                if (x < 5) {
                    x = 5;
                }
                if (x + 60 > window.innerWidth) {
                    x = window.innerWidth - 60;
                }
            }
            return {
                left: `${x}px`,
                top: `${pointerY.value - 80}px`,
                width: `${display.cellSize.width * 2}px`,
                height: `${display.cellSize.height * 2}px`,
            };
        });

        const onmousedown = (cell: Cell, ev: MouseEvent) => {
            /* eslint-disable @typescript-eslint/no-explicit-any */
            if (ev.button == 2 || cell.pressed) {
                return;
            }
            if (pressInterval) {
                clearInterval(pressInterval);
            }
            if ((ev as any).touches) {
                isTouchEvent = true;
                pointerX.value = (ev as any).touches[0].clientX;
                pointerY.value = (ev as any).touches[0].clientY;
            } else {
                isTouchEvent = false;
                pointerY.value = ev.clientY;
                pointerX.value = ev.clientX;
            }
            isMoved = false;
            pressCounter.value = 0;
            pressInterval = setInterval(() => {
                pressCounter.value += 1;
                cellPress.value = cell;
            }, 700);
        };

        const onmousemove = (cell: Cell, ev: MouseEvent) => {
            ev.stopPropagation();
            isMoved = true;
            if (pressInterval) {
                clearInterval(pressInterval);
            }
        };

        const onmouseup = (cell: Cell, ev: MouseEvent) => {
            if (isMoved) {
                return;
            }
            if (cell.pressed) {
                return;
            }
            if (ev.button == 2) {
                if (!cell.isFlag && !cell.isMaybe) {
                    bus.cellFlagCmd$.next({
                        cell,
                    });
                } else if (cell.isFlag) {
                    bus.cellMaybeCmd$.next({
                        cell,
                    });
                } else {
                    bus.cellClearMarksCmd$.next({
                        cell,
                    });
                }
                return;
            }
            cellPress.value = null;
            if (pressInterval) {
                clearInterval(pressInterval);
            }
            if (pressCounter.value == 0) {
                bus.cellSafeCmd$.next({
                    cell,
                });
            } else if (pressCounter.value % 3 === 1) {
                bus.cellFlagCmd$.next({
                    cell,
                });
            } else if (pressCounter.value % 3 === 2) {
                bus.cellMaybeCmd$.next({
                    cell,
                });
            } else {
                bus.cellClearMarksCmd$.next({
                    cell,
                });
            }
            pressCounter.value = 0;
        };

        return {
            cells,
            getCellStyle,
            getCellClass,
            onmousedown,
            onmouseup,
            cellPress,
            getPressInfoStyle,
            pressCounter,
            onmousemove,
        };
    },
});
</script>

<style scoped>
.board-wrapper {
    width: 100%;
}
.cell {
    position: absolute;
    box-sizing: border-box;
    background-color: #bdbdbd;
    color: #999999;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    border-bottom: solid 2px #7b7b7b;
    border-right: solid 2px #7b7b7b;
    border-top: solid 2px #fff;
    border-left: solid 2px #fff;
    vertical-align: middle;
}
.cell:hover {
    background-color: #cecfcc;
}
.cell.pressed,
.cell.blank {
    border-left: solid 1px #7b7b7b;
    border-top: solid 1px #7b7b7b;
    border-bottom: none;
    border-right: none;
    background-color: #b5b5b5;
}

.cell.pressed.mine-error {
    background-color: #e55050;
}

.mine-press-info {
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    vertical-align: middle;
    top: 10px;
    left: 50px;
    color: #000;
    font-size: 30px;
    font-weight: bold;
    position: fixed;
    z-index: 10;
}
</style>
