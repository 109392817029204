import { Subject } from 'rxjs';
import { Cell } from '@/games/minesweeper/models';

class Bus {
    cellSafeCmd$ = new Subject<CellSafeCmd>();

    cellFlagCmd$ = new Subject<CellFlagCmd>();

    cellMaybeCmd$ = new Subject<CellMaybeCmd>();

    cellClearMarksCmd$ = new Subject<CellClearMarksCmd>();

    firstMoveMineEvent$ = new Subject<FirstMoveMineEvent>();
}

export const bus = new Bus();

export type CellSafeCmd = {
    cell: Cell;
};

export type CellFlagCmd = {
    cell: Cell;
};

export type CellMaybeCmd = {
    cell: Cell;
};

export type CellClearMarksCmd = {
    cell: Cell;
};

export type FirstMoveMineEvent = {
    cell: Cell;
};
