
import { defineComponent, ref, computed } from 'vue';
import { gameQuery } from '@/state/game/game.query';
import { Cell } from '@/games/minesweeper/models';
import { Display } from '@/games/minesweeper/display';
import { Size } from '@/core/models';
import { cellsQuery } from '@/games/minesweeper/state/cells/cells.query';
import { bus } from '@/games/minesweeper/bus';

export default defineComponent({
    setup() {
        const gameSize = ref<Size>({ width: 0, height: 0 });
        const cells = ref<Cell[]>([]);
        const cellPress = ref<Cell | null>(null);
        const pressCounter = ref(0);
        let display = new Display({ width: 0, height: 0 }, { rows: 0, columns: 0 });
        const pointerX = ref(0);
        const pointerY = ref(0);
        let pressInterval: number | undefined = undefined;
        let isMoved = false;
        let isTouchEvent = false;

        cellsQuery.cells$.subscribe((list) => {
            display = new Display(gameQuery.getValue().gameSize, cellsQuery.getValue().boardSize);
            cells.value = list;
        });

        gameQuery.gameSize$.subscribe((size) => {
            gameSize.value = size;
            display = new Display(size, cellsQuery.getValue().boardSize);
            cells.value = [...cells.value];
        });

        const getCellStyle = (cell: Cell) => {
            const pos = display.getCellPosition(cell);
            let color = '#000000';
            if (cell.hasMine || cell.isMaybe) {
                color = '#000000';
            } else if (cell.hintNumber == 1) {
                color = '#3182CE';
            } else if (cell.hintNumber == 2) {
                color = '#2F855A';
            } else if (cell.hintNumber == 3) {
                color = '#C53030';
            } else if (cell.hintNumber == 4) {
                color = '#2C5282';
            } else if (cell.hintNumber >= 5) {
                color = '#97266D';
            }
            return {
                left: `${pos.x}px`,
                top: `${pos.y}px`,
                width: `${display.cellSize.width}px`,
                height: `${display.cellSize.height}px`,
                lineHeight: `${display.cellSize.height - 3}px`,
                color,
            };
        };

        const getCellClass = (cell: Cell) => {
            const arr = [];
            if (cell.pressed) {
                arr.push('pressed');
                if (cell.isError) {
                    arr.push('mine-error');
                }
            }
            return arr.join(' ');
        };

        const getPressInfoStyle = computed(() => {
            if (!cellPress.value) {
                return {};
            }
            let x = pointerX.value - 25;
            if (isTouchEvent) {
                if (x < 5) {
                    x = 5;
                }
                if (x + 60 > window.innerWidth) {
                    x = window.innerWidth - 60;
                }
            }
            return {
                left: `${x}px`,
                top: `${pointerY.value - 80}px`,
                width: `${display.cellSize.width * 2}px`,
                height: `${display.cellSize.height * 2}px`,
            };
        });

        const onmousedown = (cell: Cell, ev: MouseEvent) => {
            /* eslint-disable @typescript-eslint/no-explicit-any */
            if (ev.button == 2 || cell.pressed) {
                return;
            }
            if (pressInterval) {
                clearInterval(pressInterval);
            }
            if ((ev as any).touches) {
                isTouchEvent = true;
                pointerX.value = (ev as any).touches[0].clientX;
                pointerY.value = (ev as any).touches[0].clientY;
            } else {
                isTouchEvent = false;
                pointerY.value = ev.clientY;
                pointerX.value = ev.clientX;
            }
            isMoved = false;
            pressCounter.value = 0;
            pressInterval = setInterval(() => {
                pressCounter.value += 1;
                cellPress.value = cell;
            }, 700);
        };

        const onmousemove = (cell: Cell, ev: MouseEvent) => {
            ev.stopPropagation();
            isMoved = true;
            if (pressInterval) {
                clearInterval(pressInterval);
            }
        };

        const onmouseup = (cell: Cell, ev: MouseEvent) => {
            if (isMoved) {
                return;
            }
            if (cell.pressed) {
                return;
            }
            if (ev.button == 2) {
                if (!cell.isFlag && !cell.isMaybe) {
                    bus.cellFlagCmd$.next({
                        cell,
                    });
                } else if (cell.isFlag) {
                    bus.cellMaybeCmd$.next({
                        cell,
                    });
                } else {
                    bus.cellClearMarksCmd$.next({
                        cell,
                    });
                }
                return;
            }
            cellPress.value = null;
            if (pressInterval) {
                clearInterval(pressInterval);
            }
            if (pressCounter.value == 0) {
                bus.cellSafeCmd$.next({
                    cell,
                });
            } else if (pressCounter.value % 3 === 1) {
                bus.cellFlagCmd$.next({
                    cell,
                });
            } else if (pressCounter.value % 3 === 2) {
                bus.cellMaybeCmd$.next({
                    cell,
                });
            } else {
                bus.cellClearMarksCmd$.next({
                    cell,
                });
            }
            pressCounter.value = 0;
        };

        return {
            cells,
            getCellStyle,
            getCellClass,
            onmousedown,
            onmouseup,
            cellPress,
            getPressInfoStyle,
            pressCounter,
            onmousemove,
        };
    },
});
